import React from 'react';
import pattern from './../Cursor/cursor2blur.png';
import pattern2 from './../Cursor/cursor3blur.png';

const patter1Image = new Image();
patter1Image.src = pattern;

const patter2Image = new Image();
patter2Image.src = pattern2;

export class OilPainting extends React.PureComponent {
    canvasRef = React.createRef();
    context;

    componentDidMount() {
        const canvas = this.canvasRef.current;
        this.context = canvas.getContext('2d');

        const width = window.innerWidth;
        const height = window.innerHeight;

        canvas.width = width;
        canvas.height = height;
        canvas.addEventListener('mousemove', this.onMouseMove, false);
    }

    render() {
        return <canvas
            ref={this.canvasRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }}
        />;
    }

    onMouseMove = (e) => {
        requestAnimationFrame(() => {
            const currentPoint = {x: e.clientX + 25, y: e.clientY + 25};
            const lastPoint = this.lastPoint || currentPoint;

            const dist = distanceBetween(lastPoint, currentPoint);
            const angle = angleBetween(lastPoint, currentPoint);

            for (let i = 0; i < dist; i++) {
                const x = lastPoint.x + (Math.sin(angle) * i) - 25;
                const y = lastPoint.y + (Math.cos(angle) * i) - 25;

                const image = lastPoint.x > currentPoint.x ? patter2Image : patter1Image;
                this.context.drawImage(image, x, y, 20, 20);
            }

            setTimeout(() => {
                for (let i = 0; i < dist; i++) {
                    const x = lastPoint.x + (Math.sin(angle) * i) - 25;
                    const y = lastPoint.y + (Math.cos(angle) * i) - 25;
                    requestAnimationFrame(() => {
                        clearCircle(this.context, x, y, 25 / 2)
                    });
                }
            }, 300);

            this.lastPoint = currentPoint;
        });
    };
}

function distanceBetween(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}

function angleBetween(point1, point2) {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
}

function clearCircle(context, x, y, radius) {
    context.save();
    context.globalCompositeOperation = 'destination-out';
    context.beginPath();
    context.arc(x + radius, y + radius, radius * 1.5, 0, radius * Math.PI, false);
    context.fill();
    context.restore();
}
