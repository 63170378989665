import React from 'react';
import styles from './App.module.css';
import {Logo} from './components/Logo/Logo';
import {Cursor} from './components/Cursor/Cursor';
import {Project} from './components/Project/Project';
import {projects} from './components/Project/projects';
import element1 from './elements/01.png';
import {OilPainting} from './components/OilPainting/OilPainting';
import {AboutMe} from './components/AboutMe/AboutMe';
import {Footer} from './components/Footer/Footer';
import ScrollableAnchor from 'react-scrollable-anchor';
import {Repos} from "./components/Repos/Repos";
import GitHubButton from 'react-github-btn';

export class App extends React.PureComponent {
    render() {
        const isMobile = window.innerWidth < 600;
        return (
            <>
                <header className={styles.mainHeader}>
                    <Logo/>
                </header>
                <section className={styles.aboutMeWrapper}>
                    <AboutMe/>
                    <img className={styles.element1} src={element1} alt=""/>
                </section>
                <ScrollableAnchor id="github">
                    <section className={styles.opensourceWrapper}>
                        <div className={styles.opensource}>
                            <h3>My opensource projects</h3>
                            <h3>
                                <GitHubButton href="https://github.com/chornaya-com"
                                              aria-label="Follow @chornaya-com on GitHub">Follow me
                                    (@chornaya-com)</GitHubButton>
                            </h3>
                            <Repos/>
                        </div>
                    </section>
                </ScrollableAnchor>
                <ScrollableAnchor id="projects">
                    <section className={styles.projectsWrapper}>
                        {projects.map(project => <Project key={project.title} {...project} />)}
                    </section>
                </ScrollableAnchor>


                <Footer/>

                {!isMobile && <OilPainting/>}
                {!isMobile && <Cursor/>}
            </>
        );
    }
}
