import React from 'react';
import styles from './Cursor.module.css';


export class Cursor extends React.PureComponent {
    cursor = React.createRef();

    componentDidMount() {
        document.addEventListener('mousemove', this.onMouseMove);
    }

    render() {
        return (
            <div
                ref={this.cursor}
                className={styles.cursorWrapper}
            >
                <div className={styles.cursor}/>
            </div>
        );
    }

    onMouseMove = (event) => {
        const cursor = this.cursor.current;

        if (cursor) {
            cursor.style.transform = `translate3d(${event.clientX}px, ${event.clientY}px, 0)`;
        }
    }
}
