import React from 'react';
import styles from './AboutMe.module.css';

export class AboutMe extends React.PureComponent {
    render() {
        return (
            <div className={styles.aboutMe}>
                <div className={styles.profile}>
                    <div className={styles.description}>
                        <h1>
                            Hello, <br/>
                            I’m Viktoryia Chornaya.
                        </h1>
                        <div className={styles.descriptionText}>
                            <p>
                                Based in London <span role='img' aria-label="GB">🇬🇧</span>
                            </p>
                            <p>
                                <span>Software Developer</span> at <a
                                href="https://www.faceit.com/" target="_blank"
                                rel="noreferrer noopener">Faceit</a> <span
                                role='img' aria-label="game">🎮</span>
                            </p>
                            <p>
                                Tech
                                stack: <span>Javascript</span>, <span>Typescript</span>, <span>React</span>, <span>Redux</span>, <span>Styled Components</span>, <span>CSS/SCSS</span>,
                                etc.
                            </p>
                            <p>
                                After several years of successful experience as a Project Manager,
                                I shifted my focus to Software Development, as I've always been obsessed with
                                technology.
                                That's how I started doing what I've been doing for <span>3+</span> years.

                            </p>
                            <p>
                                Besides that, I am a certified <a
                                href="http://www.scrum.org/user/205297"
                                target="_blank" rel="noreferrer noopener">Professional
                                Scrum
                                Master</a>.
                            </p>
                            <div className={styles.finalDescription}>
                                <p>
                                    But nothing will present me better than the results of my work. Scroll further!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
