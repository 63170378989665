import React from 'react';
import ImageGallery from "react-image-gallery";
import styles from './Project.module.css';

export class Project extends React.PureComponent {
    render() {
        const {
            image,
            title,
            linkHref,
            linkTitle,
            description,
            images,
        } = this.props;

        return (
            <div className={styles.projectWrapper}>
                <div className={styles.project}>
                    <div className={styles.image}>
                        {images && <ImageGallery
                            showNav={false}
                            showPlayButton={false}
                            items={[image, ...images].map(img => ({
                                original: img,
                                thumbnail: img,
                            }))}
                        />}

                        {!images && <img src={image} alt={title}/>}
                    </div>
                    <div className={styles.descriptionWrapper}>
                        <h2>{title}</h2>
                        {linkTitle && <a href={linkHref} target='_blank' rel="noopener noreferrer">
                            {linkTitle} ▸
                        </a>}
                        <div className={styles.description} dangerouslySetInnerHTML={{__html: description}} />
                    </div>
                </div>
            </div>
        );
    }
}
