import * as React from "react";
import "./Repos.css";

const API_URL = "https://api.github.com/users/chornaya-com/repos?page=1&per_page=8&sort=updated";

const getStarBadgeImageSrc = (full_name) => `https://img.shields.io/github/stars/${full_name}.svg?style=social`;

export class Repos extends React.PureComponent {
    state = {repos: []};

    componentDidMount() {
        fetch(API_URL).then(async response => {
            const data = await response.json();

            const repos = data.map((repo) => ({
                name: repo.name,
                full_name: repo.full_name,
                description: repo.description,
                stargazers_count: repo.stargazers_count
            }));

            this.setState({repos});
        });
    }

    render() {
        return this.state.repos.map(({name, full_name, description, stargazers_count},) => {
            if (stargazers_count >= 2) {
                return (
                    <a key={full_name} href={`https://github.com/${full_name}`} target="_blank" rel="noreferrer"
                       className="RepoLink">
                        <div className="Repo">
                            <div className={"repoInfo"}>
                                <div className={"repoName"}>{name}</div>
                                <div className={"repoDescription"}>{description}</div>
                            </div>

                            <img src={getStarBadgeImageSrc(full_name)} alt="stars"/>
                        </div>
                    </a>
                );
            }
            return null;
        });
    }
}
