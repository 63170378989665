import React from 'react';
import styles from './Footer.module.css';
import dog from './dog.png';
import footerLine from './footerLine.png';

export class Footer extends React.PureComponent {
    render() {
        return (
            <div className={styles.footerContainer}>
                <div className={styles.footer}>
                    <div className={styles.mailMe}>
                        <p>Any ideas for collaboration?</p>
                        <p>Then email me 📧 <a href="mailto: me@chornaya.com">me@chornaya.com</a></p>
                        <p>or check my <a href="https://www.linkedin.com/in/vchornaya/" target="_blank"
                                          rel="noreferrer">Linked<b>in</b> profile</a>.</p>
                    </div>
                    <div className={styles.dogContainer}>
                        <img className={styles.dog} src={dog} alt="dog"/>
                    </div>
                </div>
                <img className={styles.footerLine} src={footerLine} alt="footer"/>
            </div>
        );
    }
}
