import evoLp from './pics/EVO_lp.png';
import office01 from './pics/office_01.png';
import office02 from './pics/office_02.png';
import office03 from './pics/office_03.png';
import office04 from './pics/office_04.png';
import office05 from './pics/office_05.png';
import getEat01 from './pics/geteat1.png';
import getEat02 from './pics/geteat2.png';
import getEat03 from './pics/geteat3.png';
import getEat04 from './pics/geteat4.png';
import getEat05 from './pics/geteat5.png';
import gitNation01 from './pics/gn0.jpg';
import gitNation02 from './pics/gn1.jpeg';
import gitNation03 from './pics/gn2.jpeg';
import gitNation04 from './pics/gn3.jpeg';
import events01 from './pics/conf_01.png';
import events02 from './pics/conf_02.png';
import events03 from './pics/conf_03.png';
import events04 from './pics/conf_04.png';
import events05 from './pics/conf_05.png';
import events06 from './pics/conf_06.png';
import shop01 from './pics/shop_01.png';
import shop02 from './pics/shop_02.png';
import shop03 from './pics/shop_03.png';
import shop04 from './pics/shop_04.png';
import shop05 from './pics/shop_05.png';

export const projects = [
    {
        image: office01,
        title: 'Opening a new engineering hub',
        linkHref: 'https://companies.dev.by/evolution-gaming/',
        linkTitle: 'companies.dev.by/evolution-gaming',
        description: `
Expanding international presence of the Engineering Department and coordination of the entire cycle of opening a new Engineering Hub in a new market from initiation to launch.
This project was realized less then in 6 months, and now this center is a resident of HTP with 100+ employees.
        `,
        images: [
            office02,
            office03,
            office04,
            office05,
        ]
    },
    {
        image: getEat01,
        title: 'GetEat',
        description: `
First food delivery aggregator in Belarus.
Web app & mobile application for both platforms (iOS/Android) that gives possibility to order food
from any restaurants and cafes in Belarus.
Complex CRM system for restaurants.
It was a great experience of working on a startup, as we went a full cycle of project creation: from idea to launch. My responsibilities spanned different areas and included working in different roles.
        `,
        images: [
            getEat02,
            getEat03,
            getEat04,
            getEat05,
        ]
    },
    {
        image: evoLp,
        title: 'Engineering Portal',
        linkHref: 'https://eng.evolutiongaming.com/',
        linkTitle: 'eng.evolutiongaming.com',
        description: `
   Working as a part of a development team we developed and deployed the project of Engineering landing page, that also included communication with UX department, roadmaps, managing the Product Backlog, estimation, budgeting, resource planning, etc.
        `,
    },
    {
        image: gitNation01,
        images: [
            gitNation02,
            gitNation03,
            gitNation04,
        ],
        title: 'GitNation',
        linkHref: 'https://gitnation.org/',
        linkTitle: 'gitnation.org',
        description: `
An active member of GitNation Community for several years, organizing and participating in the largest online and offline conferences and meetups for developers around the world. The most known are:
<br><br><a href="https://remote.reactsummit.com/">React Summit</a>,
<br><a href="https://live.jsnation.com/">JS Nation Live</a>,  
<br><a href="https://reactadvanced.com/">React Advanced London</a>, 
<br><a href="https://reactday.berlin/">React Day Berlin</a>, etc.
  `,
    },
    {
        image: events01,
        title: 'Engineering Events',
        description: `
            Representing the Engineering Department at the international conferences, active participation in different events, organising and conducting them to create a positive image of the department and the company. Concept development, organization and holding of company own internal and external events for 100+ participants.
        `,
        images: [
            events02,
            events03,
            events04,
            events05,
            events06,
        ]
    },
    {
        image: shop01,
        title: 'Development of web applications',
        description: `
        Development and launch of 10+ online marketplaces on different CMS such as DLE, WordPress, Joomla, etc.
        Content-management, including hiring, planning, budgeting and advertising.
        `,
        images: [
            shop02,
            shop03,
            shop04,
            shop05,
        ]
    },
]
